
import Vue from "vue"
import { mergeData } from "vue-functional-data-merge"

export default Vue.extend({
  name: "RaffleButton",
  functional: true,
  props: {
    disabled: {
      type: Boolean
    }
  },
  render(h, { props, children, data }) {
    return h(
      "button",
      mergeData(data, {
        attrs: { type: "button", disabled: props.disabled },
        class: { "--default-disabled": props.disabled },
        staticClass:
          "raffle-button px-3 rounded uppercase font-bold text-white --with-default-focus"
      }),
      children
    )
  }
})
