











































































import Vue from "vue"
import { ResizeObserver } from "vue-resize"
import mixins from "vue-typed-mixins"
import type { WithRefs } from "vue-typed-refs"
import invariant from "invariant"
import { TweenMax, Power0, Power2 } from "gsap/TweenMax"
import Mission from "@shared/enums/Mission"
import type { User } from "@/types/user"

import GetterTypes from "@/store/getter-types"

import UUIDMixin from "@/mixins/uuid"
import { RtbAlert, RtbSpinner } from "@/components/ui"

import RaffleModel from "../core"

import RaffleUser from "./RaffleUser.vue"
import RaffleButton from "./RaffleButton.vue"
import RaffleToggle from "./RaffleToggle.vue"

import { mapGetters } from "vuex"

const DEFAULT_SOUND = require("../assets/sound.mp3")

import { USER_HEIGHT, SCROLL_REPEAT_COUNT, SCROLL_TIME } from "../constants"

export type Refs = {
  inner: HTMLDivElement
  users?: HTMLUListElement
}

export default (RaffleModel as WithRefs<Refs, typeof RaffleModel>).extend({
  name: "RaffleComponent",
  props: {
    sound: {
      type: String,
      default: DEFAULT_SOUND
    }
  },
  components: {
    ResizeObserver,
    RtbAlert,
    RtbSpinner,
    RaffleUser,
    RaffleButton,
    RaffleToggle
  },
  computed: {
    ...mapGetters("auth", ["isModerator"]),
    isWhiteElephant() {
      return (
        this.$store.getters.gameStatus?.current_mission?.behavior ===
        Mission.WhiteElephant
      )
    },
    className() {
      return [
        "raffle absolute top-0 left-0 rtb-background-primary-dark",
        { "raffle--active": this.active }
      ]
    },
    volume(): number {
      return this.$store.getters[GetterTypes.VOLUME]
    }
  },
  watch: {
    winner(value: string | undefined, prevValue: string | undefined) {
      if (value) {
        this.spinning = true
        const { users: element } = this.$refs

        this.resetOffset()

        this._audio.currentTime = 0
        this._audio.play()

        this._lastCenteredElement = this._getCenterElement()

        this.setAnimating(true)

        this._tween = TweenMax.to(element, SCROLL_TIME, {
          y: -element.scrollHeight + element.clientHeight,
          repeat: SCROLL_REPEAT_COUNT,
          clearProps: "all",
          ease: Power0.easeNone,
          onUpdate: this._onUpdate,
          onComplete: () => {
            this.scrollToWinner(SCROLL_TIME * 2)
              .then(() => {
                this.spinning = false
              })
              .finally(() => {
                this.setAnimating(false)
                if (!this.raffleInAGameActive) this.initializeWinnerToGame()
              })
          }
        })
      } else {
        if (
          this.raffleInAGameActive &&
          prevValue &&
          !value &&
          this.raffleAnimating
        ) {
          // there is a reset, if animation is running,
          // let's stop it
          this._tween?.kill()
          this.setAnimating(false)
        }
        this.resetOffset()
      }
    },
    height() {
      if (this.winner && this.$refs.users) {
        this.scrollToWinner()
      }
    },
    available(value: boolean) {
      if (value) {
        this.$nextTick(() => {
          if (this.winner && this.$refs.users) {
            this.scrollToWinner()
          }
        })
      }
    },
    sound: {
      handler(value: string) {
        this._audio = new Audio(value)
        this._audio.currentTime = 0
        this._audio.volume = this.volume
      },
      immediate: true
    },
    volume(value: number) {
      this._audio.volume = this.volume
    }
  },
  created() {
    this._lastCenteredElement = null
  },
  mounted() {
    this.height = this.$refs.inner.clientHeight
  },
  beforeDestroy() {
    if (this._audio.paused === false) {
      this._audio.pause()
    }

    if (this._tween) {
      this._tween.kill()
    }
  },
  methods: {
    spin() {
      this.computeWinner()
    },
    resetOffset() {
      const { users } = this.$refs
      if (users !== undefined) {
        TweenMax.to(users, 0, {
          y: 0
        })
      }
    },
    scrollToWinner(time: number): Promise<boolean> {
      invariant(this.winner, "There is no winner to scroll to")
      const { users: element } = this.$refs
      const winner = document.getElementById(this._generateID(this.winner))

      if (winner) {
        return new Promise(resolve => {
          this._tween = TweenMax.to(element, time, {
            y: -winner.offsetTop + element.offsetHeight / 2 - USER_HEIGHT / 2,
            ease: time !== 0 ? Power2.easeOut : undefined,
            onUpdate: time !== 0 ? this._onUpdate : undefined,
            onComplete: () => {
              if (this._lastCenteredElement) {
                this._lastCenteredElement.classList.remove("--active")
              }
              this._lastCenteredElement = null
              resolve(true)
            }
          })
        })
      }

      return Promise.resolve(false)
    },
    handleResize({ height }: { height: number }) {
      this.height = height
    },
    canApplyAttributes(index: number) {
      const center = Math.floor(this.normalizedUsers.length / 2)
      const half = Math.floor(this.unassignedOnlinePlayers.length / 2)
      const even = this.unassignedOnlinePlayers.length % 2 === 0
      return (
        index >= center - half &&
        (even ? index < center + half : index <= center + half)
      )
    },
    _onUpdate() {
      const centeredElement = this._getCenterElement()
      if (centeredElement !== this._lastCenteredElement) {
        if (this._lastCenteredElement !== null) {
          this._lastCenteredElement.classList.remove("--active") /* else {
            this._audio.pause()
            this._audio.currentTime = 0
            this._audio.play()
          } */
          /* if (this.spinning && this._audio.paused) {
            this._audio.play().catch(e => {
              console.error(e)
            })
          } */
        }
        centeredElement.classList.add("--active")
        this._lastCenteredElement = centeredElement
      }
    },
    _getCenterElement() {
      const { users } = this.$refs
      if (users !== undefined) {
        let offset = 0
        if (users._gsTransform !== undefined) {
          offset = -users._gsTransform.y
        }
        const center = users.clientHeight / 2 + offset
        const collection = Array.prototype.slice.call(users.children)
        const element = collection.find(
          item => item.offsetTop < center && item.offsetTop + 56 > center
        )
        return element
      }
    },
    _generateID(userID: string) {
      return `${this._uid}-${userID}`
    }
  }
})
