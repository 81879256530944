import { db } from "@/firebase"
import { FirebaseService } from "@/services/firebase.service"

export default class RaffleService extends FirebaseService {
  toggleActive(orgID: string, gameID: string, value: boolean) {
    return db
      .auxiliary()
      .ref(`org/${orgID}/game/${gameID}/gameStatus/raffle/active`)
      .set(value)
  }

  setWinner(orgID: string, gameID: string, winnerID: string | null) {
    return db
      .auxiliary()
      .ref(`org/${orgID}/game/${gameID}/gameStatus/raffle/winner`)
      .set(winnerID)
  }

  setRaffleInGame(orgID: string, gameID: string, value: boolean | null) {
    return db
      .auxiliary()
      .ref(`org/${orgID}/game/${gameID}/gameStatus/raffle/raffleInGame`)
      .set(value)
  }
}
