import Vue from "vue"
import invariant from "invariant"
import randomItem from "random-item"
import randomInt from "random-int"

import { User, UserRole } from "@/types/user"
import Mode from "@shared/enums/Mode"
import UserEntity from "@shared/User"
import ActionTypes from "@/store/action-types"

import RaffleService from "../services"
import RaffleModule from "../store"
import MutationTypes from "../store/mutation-types"

import {
  USER_HEIGHT,
  MIN_USERS_COUNT,
  DESIRED_PAGE_COUNT,
  STORE_MODULE_NAME,
  IN_GAME_RAfflE_MODES
} from "../constants"

import { copyItems } from "../helpers"
import { mapGetters, mapState } from "vuex"
import { GameType } from "@/entities/GameType"

export default Vue.extend({
  name: "RaffleModel",
  data() {
    return {
      loading: false,
      height: 0,
      users: [] as User[],
      spinning: false,
      inGameUsers: [] as User[],
      lockedSelectedMeetingPlayers: {}
    }
  },
  computed: {
    ...mapGetters(["moderatorID"]),
    orgID(): string {
      return this.$store.getters.orgID
    },
    clientID(): string {
      return this.$store.state.auth.clientID
    },
    gameID(): string {
      return this.$store.getters.gameID
    },
    game(): string {
      return this.$store.getters.game
    },
    gameMode() {
      return this.$store.getters?.getCurrentMode ?? null
    },
    isHost(): boolean {
      return this.$store.state.auth.user.role === UserRole.Host
    },
    selectedMeetingUsers() {
      return this.$store.getters?.getSelectedMeetingUsers ?? []
    },
    /**
     * @description We also include the winner of the "Raffle".
     */
    unassignedOnlinePlayers(): User[] {
      const winner =
        this.winner !== undefined
          ? this.raffleUsers.find(user => user.id === this.winner)
          : undefined

      const onlinePlayersPredicate = this.getUnassignedOnlinePlayersPredicate()
      const unassignedOnlinePlayers = this.raffleUsers.filter(
        onlinePlayersPredicate
      )

      const start = randomInt(
        0,
        Math.max(unassignedOnlinePlayers.length - this.usersPerPage, 0)
      )
      const end = start + this.usersPerPage

      const array =
        unassignedOnlinePlayers.length > this.usersPerPage
          ? unassignedOnlinePlayers.slice(start, end)
          : unassignedOnlinePlayers

      if (
        winner !== undefined &&
        array.find(user => user.id === winner.id) === undefined
      ) {
        this.$set(array, randomInt(0, array.length), winner)
      }

      return array
    },
    usersPerPage(): number {
      return Math.ceil(this.height / USER_HEIGHT)
    },
    normalizedUsers(): User[] {
      if (this.usersPerPage === 0) return []
      if (this.unassignedOnlinePlayers.length >= this.usersPerPage) {
        const result = [].concat(this.unassignedOnlinePlayers)
        return copyItems(result, DESIRED_PAGE_COUNT - 1)
      }

      const result = [].concat(this.unassignedOnlinePlayers)
      this._populateWithUsers(result)
      return copyItems(result, DESIRED_PAGE_COUNT - 1)
    },
    gameStatus() {
      return this.$store.getters.gameStatus ?? {}
    },
    active(): boolean {
      return this.gameStatus?.raffle?.active ?? false
    },
    available(): boolean {
      return this.unassignedOnlinePlayers.length >= MIN_USERS_COUNT
    },
    winner(): string | undefined {
      return this.gameStatus?.raffle?.winner
    },
    config() {
      return {
        USER_HEIGHT,
        MIN_USERS_COUNT
      }
    },
    onlineUsersArray() {
      return this.$store.getters?.onlineUsersArray ?? []
    },
    raffleInAGameActive(): boolean {
      return (
        (this.gameStatus?.raffle?.raffleInGame ?? false) &&
        IN_GAME_RAfflE_MODES.includes(this.gameMode)
      )
    },
    raffleAnimating(): boolean {
      return this.$store.state.raffle?.animating ?? false
    },
    getInGameAvailableUsers() {
      const onlinePlayersPredicate = this.getUnassignedOnlinePlayersPredicate()
      return this.onlineUsersArray.filter(onlinePlayersPredicate)
    },
    raffleUsers() {
      return this.raffleInAGameActive ? this.inGameUsers : this.users
    },
    isGreenRoom() {
      return this.game?.gameType === GameType.GreenRoom
    }
  },
  watch: {
    active: {
      handler(value: boolean) {
        if (value) {
          if (!this.raffleInAGameActive) {
            this.getUsers()
          } else {
            this.inGameUsers = this.getInGameAvailableUsers
            this.lockedSelectedMeetingPlayers = { ...this.selectedMeetingUsers }
          }
        }
      },
      immediate: true
    },
    winner(value: string | undefined, prevValue: string | undefined) {
      if (value === undefined) {
        if (!this.raffleInAGameActive) {
          this.getUsers()
        } else {
          if (prevValue && !value) {
            this.inGameUsers = this.getInGameAvailableUsers
            this.lockedSelectedMeetingPlayers = this.selectedMeetingUsers
          }
        }
      }
    },
    raffleInAGameActive: {
      handler(val, prevVal) {
        if (val && !prevVal) {
          this._unwatchGetInGameAvailableUsers = this.$watch(
            "getInGameAvailableUsers",
            val => {
              if (this.winner || this.raffleAnimating) return
              this.inGameUsers = val
            }
          )
          this._unwatchSelectedMeetingUsers = this.$watch(
            "selectedMeetingUsers",
            () => {
              if (this.winner || this.raffleAnimating) return
              this.lockedSelectedMeetingPlayers = this.selectedMeetingUsers
            }
          )
          this._unwatchRaffleAnimating = this.$watch(
            "raffleAnimating",
            value => {
              if (!value && this._computing) {
                if (
                  this.gameMode === Mode.Theater ||
                  (this.gameMode === Mode.Meeting && this.isGreenRoom)
                ) {
                  this.$store.dispatch(
                    ActionTypes.UPDATE_SELECTED_MEETING_USER,
                    {
                      userID: this.winner,
                      value: true
                    }
                  )
                } else if (Mode.Meeting === this.gameMode) {
                  this.$store.dispatch(
                    "updateMeetingModeActiveUser",
                    this.winner
                  )
                } else if (Mode.Social === this.gameMode) {
                  this.scribeWinner()
                }

                this.loading = false
                this._computing = false
              }
            }
          )
        } else {
          this._unwatchGetInGameAvailableUsers &&
            this._unwatchGetInGameAvailableUsers()
          this._unwatchSelectedMeetingUsers &&
            this._unwatchSelectedMeetingUsers()
          this._unwatchRaffleAnimating && this._unwatchRaffleAnimating()
        }
      },
      immediate: true
    }
  },
  beforeCreate() {
    this.$store.registerModule(STORE_MODULE_NAME, RaffleModule)
  },
  created() {
    this.service = new RaffleService()
    this.scribeTimeout = null
  },
  beforeDestroy() {
    this.setAnimating(false)
    this.$store.unregisterModule(STORE_MODULE_NAME)
    clearTimeout(this.scribeTimeout)
  },
  methods: {
    getUsers() {
      this.loading = true
      const isUserAlive = this.$store.getters.isUserAlive
      return this.$services
        .get("user")
        .then(service => {
          return service.getUsersByClientID(this.clientID).then(users => {
            this.users = users.filter(isUserAlive)
          })
        })
        .finally(() => {
          this.loading = false
        })
    },
    toggleActive() {
      this.service.toggleActive(this.orgID, this.gameID, !this.active)
    },
    async computeWinner() {
      this._computing = false
      this.loading = true

      let winner: User

      if (this.unassignedOnlinePlayers.length === 1) {
        winner = this.unassignedOnlinePlayers[0]
      } else {
        do {
          winner = randomItem(this.unassignedOnlinePlayers)
        } while (winner.id === this.winner)
      }

      await this.service.setWinner(this.orgID, this.gameID, winner.id)

      this._computing = true
    },
    initializeWinnerToGame() {
      invariant(this.winner, "There is no winner to initialize to the game")
      this.loading = true
      return this.$store
        .dispatch(ActionTypes.PUSH_USERS_TO_GAME, {
          users: [this.winner],
          gameID: this.gameID
        })
        .finally(() => {
          this.loading = false
        })
    },
    scribeWinner() {
      invariant(this.winner, "There is no winner to scribe")
      this.loading = true
      return this.$store
        .dispatch("updateUser", {
          userID: this.winner,
          obj: { selected: true }
        })
        .finally(() => {
          this.loading = false
        })
    },
    setAnimating(value: boolean) {
      this.$store.commit(
        `${STORE_MODULE_NAME}/${MutationTypes.SET_ANIMATING}`,
        value
      )
    },
    reset() {
      this.loading = true
      this.service.setWinner(this.orgID, this.gameID, null).finally(() => {
        this.loading = false
      })
    },
    _populateWithUsers(users: User[]) {
      let i = 0

      while (users.length % this.usersPerPage !== 0) {
        users.push(users[i])
        i++
        if (i > users.length - 1) {
          i = 0
        }
      }

      return users
    },
    getUnassignedOnlinePlayersPredicate() {
      if (this.raffleInAGameActive) {
        const isScribeSelection = [Mode.Meeting, Mode.Theater].includes(
          this.gameMode
        )
        const isMeetingActiveUser =
          Mode.Meeting === this.gameMode && !this.isGreenRoom
        const { path } = this.$route
        const { moderatorID, winner, lockedSelectedMeetingPlayers } = this
        const getMeetingModeActiveUser =
          this.$store.getters?.getMeetingModeActiveUser

        if (isMeetingActiveUser) {
          return user =>
            user.role === UserRole.Player &&
            !UserEntity.isOnboarding(user) &&
            this.$store.getters.isUserAlive(user) &&
            user.noRaffle !== true &&
            user.id !== moderatorID &&
            user.path === path &&
            ((getMeetingModeActiveUser === user?.id && winner === user?.id) ||
              getMeetingModeActiveUser !== user?.id)
        } else if (isScribeSelection) {
          return user =>
            user.role === UserRole.Player &&
            !UserEntity.isOnboarding(user) &&
            this.$store.getters.isUserAlive(user) &&
            user.noRaffle !== true &&
            user.id !== moderatorID &&
            user.path === path &&
            ((lockedSelectedMeetingPlayers?.[user.id] && winner === user?.id) ||
              !lockedSelectedMeetingPlayers?.[user.id])
        }

        return user =>
          user.role === UserRole.Player &&
          !UserEntity.isOnboarding(user) &&
          this.$store.getters.isUserAlive(user) &&
          user.noRaffle !== true &&
          user.id !== moderatorID &&
          user.path === path &&
          ((user.selected && winner === user?.id) || !user.selected)
      }

      return user =>
        user.gameID !== this.gameID &&
        UserEntity.isPlayer(user) &&
        !UserEntity.isOnboarding(user) &&
        user.noRaffle !== true
    }
  }
})
