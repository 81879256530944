import state from "./state"
import mutations from "./mutations"

const RaffleModule = {
  namespaced: true,
  state,
  mutations
}

export default RaffleModule
