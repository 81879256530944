
import Vue, { PropType } from "vue"
import { mergeData } from "vue-functional-data-merge"

import type { User } from "@/types/user"

import { DEFAULT_IMAGE_PROFILE_URL } from "@/config"

export default Vue.extend({
  name: "RaffleUser",
  functional: true,
  props: {
    user: {
      type: Object as PropType<User>,
      required: true
    },
    index: {
      type: Number,
      required: true
    },
    active: {
      type: Boolean as PropType<boolean | undefined>
    }
  },
  render(h, { props, data }) {
    return h(
      "div",
      mergeData(data, {
        class: {
          "raffle-user--active": props.active
        },
        staticClass: "raffle-user relative rtb-d-flex items-center"
      }),
      [
        h(
          "div",
          { staticClass: "raffle-user__left flex-shrink-0 relative bg-white" },
          [
            h("img", {
              attrs: {
                src: props.user.image || DEFAULT_IMAGE_PROFILE_URL,
                alt: `${props.user.firstname}' avatar`
              },
              staticClass:
                "raffle-user__image absolute inset-0 w-full h-full object-cover"
            })
          ]
        ),
        h(
          "div",
          {
            class: props.active ? "font-bold" : "font-medium",
            staticClass:
              "raffle-user__right relative z-1 flex-grow uppercase text-center contrast-text-color"
          },
          [
            h(
              "span",
              undefined,
              `${props.user.firstname} ${props.user.lastname}`
            )
          ]
        )
      ]
    )
  }
})
