import type { MutationTree } from "vuex"

import type { State } from "./state"

import MutationTypes from "./mutation-types"

const mutations: MutationTree<State> = {
  [MutationTypes.SET_ANIMATING](state, value: boolean) {
    state.animating = value
  }
}

export default mutations
