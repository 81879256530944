
import Vue from "vue"
import { mergeData } from "vue-functional-data-merge"

import SvgIcon from "@/components/base/SvgIcon.vue"

export default Vue.extend({
  name: "RaffleToggle",
  functional: true,
  props: {
    active: {
      type: Boolean,
      required: false
    }
  },
  render(h, { props, children, data }) {
    return h(
      "button",
      mergeData(data, {
        attrs: { type: "button" },
        staticClass:
          "raffle-toggle rtb-background-primary uppercase text-white --with-default-focus"
      }),

      [
        h("span", { staticClass: "contrast-text-color" }, [
          children,
          h(SvgIcon, {
            props: {
              name: props.active
                ? "chevron-left-regular"
                : "chevron-right-regular"
            },
            staticClass: "raffle-toggle__icon "
          })
        ])
      ]
    )
  }
})
