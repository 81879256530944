var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.className },
    [
      _c("div", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.active,
            expression: "active",
          },
        ],
        staticClass: "raffle__underlay absolute h-full overflow-hidden",
        attrs: { role: "presentation" },
      }),
      _c(
        "div",
        {
          ref: "inner",
          staticClass:
            "raffle__inner relative h-full overflow-hidden rtb-background-primary-dark",
        },
        [
          _c("div", {
            staticClass: "raffle__border absolute top-0 right-0 h-full",
            attrs: { role: "presentation" },
          }),
          _vm.available
            ? _c(
                "ul",
                {
                  ref: "users",
                  staticClass: "raffle__users reset-list h-full",
                },
                _vm._l(_vm.normalizedUsers, function (user, index) {
                  return _c(
                    "li",
                    {
                      key: index,
                      staticClass: "raffle__users-item",
                      attrs: {
                        id: _vm.canApplyAttributes(index)
                          ? _vm._generateID(user.id)
                          : undefined,
                      },
                    },
                    [
                      _c("RaffleUser", {
                        attrs: {
                          user: user,
                          index: index,
                          active:
                            _vm.winner === user.id &&
                            _vm.canApplyAttributes(index) &&
                            !_vm.spinning,
                        },
                      }),
                    ],
                    1
                  )
                }),
                0
              )
            : (_vm.isHost || _vm.isModerator) && !_vm.loading
            ? _c(
                "rtb-alert",
                { staticClass: "raffle__alert position-center" },
                [
                  _c("p", [
                    _vm._v(" There are "),
                    _c("strong", [_vm._v("not enough")]),
                    _vm._v(
                      ' users to start a "Raffle". The minimum number is '
                    ),
                    _c("strong", [_vm._v(_vm._s(_vm.config.MIN_USERS_COUNT))]),
                    _vm._v(". "),
                  ]),
                ]
              )
            : _vm._e(),
          _vm.loading && !_vm.available
            ? _c("rtb-spinner", { attrs: { centered: "" } })
            : _vm._e(),
          _c("ResizeObserver", { on: { notify: _vm.handleResize } }),
        ],
        1
      ),
      _vm.isHost || _vm.isModerator
        ? _c(
            "RaffleToggle",
            {
              staticClass: "raffle__toggle absolute",
              attrs: { active: _vm.active },
              on: { click: _vm.toggleActive },
            },
            [
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.active,
                      expression: "!active",
                    },
                  ],
                  staticClass: "mr-1",
                },
                [_vm._v(" Raffle ")]
              ),
            ]
          )
        : _vm._e(),
      _vm.active
        ? [
            _c("div", {
              staticClass: "raffle__ellipse",
              attrs: { role: "presentation" },
            }),
            _c("svg-icon", {
              staticClass: "raffle__arrow absolute text-white",
              attrs: { name: "arrow-left-2" },
            }),
            (_vm.isHost || _vm.isModerator) && !_vm.isWhiteElephant
              ? [
                  _c(
                    "RaffleButton",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.winner,
                          expression: "!winner",
                        },
                      ],
                      staticClass: "raffle__button",
                      attrs: { disabled: _vm.loading || !_vm.available },
                      on: { click: _vm.spin },
                    },
                    [_vm._v("Spin")]
                  ),
                  _c(
                    "RaffleButton",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.winner,
                          expression: "winner",
                        },
                      ],
                      staticClass: "raffle__button",
                      attrs: { disabled: _vm.spinning || _vm.loading },
                      on: { click: _vm.reset },
                    },
                    [_vm._v(_vm._s(_vm.spinning ? "Spin" : "Reset"))]
                  ),
                ]
              : _vm._e(),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }